import { computed, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { ApiClient } from '@/utils/apiClient';
import { readableCookie } from '@/utils/formatters';
import { getCookie } from '@/utils/dom';

/**
 *@module usePrefillApp
 *@description Vue composable for handling prefill data and collectiviteit (collective) number.
 *This module provides a composable that makes it easy to manage prefill data and collectiviteit number
 *based on URL parameters, cookies, or store data. It allows you to fetch prefill data, fetch products
 *based on the query string, and set or update the collectiviteit number.
 *
 *@example
 * <script setup>
 * import { usePrefillApp } from '@/composables/use-prefill-app';
 * *
 * useInitializeApp();
 * </script>
 */
export const usePrefillApp = () => {
    const store = useStore();
    const { appContext } = inject('jss');

    const isProspectFlow = computed(() => store.getters['renderInfo/isProspectFlow']);
    const propositie = computed(() => appContext().propositie);

    const fetchPrefillData = value => store.dispatch('prefill/fetchPrefillData', value);
    const fetchProductenBijQuerystring = value =>
        store.dispatch('prefill/fetchProductenBijQuerystring', value);
    const setCollectiviteitnummer = value =>
        store.dispatch('prefill/setCollectiviteitnummer', value);

    const setPrefillData = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        const hv = urlParams.get('hv');
        const combi = urlParams.get('combi');
        const av = urlParams.get('av');
        const tv = urlParams.get('tv');
        const er = urlParams.get('er');
        const cn = urlParams.get('cn');

        if (id) {
            fetchPrefillData(id);
        } else if (hv || av || tv) {
            fetchProductenBijQuerystring({
                Propositie: propositie.value,
                Basis: hv,
                Combi: combi,
                Aanvullend: av,
                Tand: tv,
                EigenRisico: er
            });
        }

        if (cn) {
            const qsCollectiviteitsnummer = getSanitizedCollectiviteitNummer(cn);
            if (qsCollectiviteitsnummer) {
                setCollectiviteitnummer(qsCollectiviteitsnummer);
                setCollectiviteitCookies(qsCollectiviteitsnummer);
            }
        } else {
            if (isProspectFlow.value) {
                const cookiePrefix = propositie.value === 'BAP' ? 'ZKA' : propositie.value;
                const cookie = getCookie(`${cookiePrefix}CollectiviteitInformatie`);
                if (cookie) {
                    const cookieInfo = readableCookie(cookie);
                    if (cookieInfo && cookieInfo.nummer !== 0) {
                        const nummer = getSanitizedCollectiviteitNummer(cookieInfo.nummer);
                        setCollectiviteitnummer(nummer);
                        setCollectiviteitCookies(nummer);
                    }
                }
            }
        }

        // check if 'prefill' exists in session storage and retrieve the data
        const prefill = JSON.parse(sessionStorage.getItem('prefill'));
        if (!prefill) return;

        if (prefill.geboortedatum) {
            store.dispatch('prefill/setGeboortedatum', prefill.geboortedatum);
        }

        sessionStorage.removeItem('prefill');
    };

    const getSanitizedCollectiviteitNummer = input => {
        if (input === null) return 0;
        // Sanitize the input. Only accept (the first set of) numbers.
        const matches = input.match(/^\d+/);
        return matches !== null ? matches[0] : 0;
    };

    const setCollectiviteitCookies = async collectiviteitnummer => {
        try {
            await ApiClient(appContext()).post(
                `/clientcontroller/salesfunnelclient/setcollectiviteitcookies`,
                {
                    Collectiviteitnummer: collectiviteitnummer,
                    Propositie: propositie.value
                }
            );
        } catch (error) {
            console.log('Kan collectiviteitcookies niet zetten:', error.message);
        }
    };

    onMounted(setPrefillData);
};
