module.exports = {
    APP_TYPE_PROSPECT: 'prospect',
    APP_TYPE_WIJZIGEN: 'wijzigen',
    APP_TYPE_STAPPENPLAN: 'stappenplan',
    EVENT_NO_BV_SELECTED: 'error:no-bv-selected',
    INKOMEN: {
        NL: 'NL',
        BL: 'BL',
        BD: 'BD',
        NO: 'NO'
    },
    LAND_NL: 'NL',
    UTV_ANDERS: 'Anders',
    MODAL_COLL_ZOEKER: 'collectiviteits-zoeker',
    REASONS: {
        OVERSTAPPEN: '1',
        OVERSTAP_HUIDIG_JAAR: '2',
        WISSEL_WERKGEVER: '3',
        MEEVERZEKERD: '4',
        VERHUIS_NAAR_NEDERLAND: '5',
        EX_MILITAIR: '6',
        EX_GEWETENS_BEZWAARDE: '8',
        WORD_18: '10',
        EX_ASIELZOEKER: '11',
        WERKEN_IN_NEDERLAND: '12',
        STUDEREN_NEDERLAND: '13',
        BUITENLANDS_STUDENT: '14'
    },
    NIEUW_DIENSTVERBAND: {
        UNDEFINED: '0',
        DEZE_OF_VORIGE_MAAND: '1',
        TOEKOMSTMAAND_DE_EERSTE: '2',
        TOEKOMSTMAAND_NIET_DE_EERSTE: '3',
        TE_LANG_GELEDEN: '4'
    },
    ROLE_VERZEKERINGNEMER: 'verzekeringnemer',
    ROLE_PARTNER: 'partner',
    ROLE_KIND: 'kind',
    BETAALWIJZE_AUTO_INCASSO: '2',
    BETAALWIJZE_EMAIL: '3',
    SCREEN_WIDTH_SM: 768,
    SCREEN_WIDTH_MD: 992,
    SCREEN_WIDTH_LG: 1200,
    COLLAPSE_MAPPER: {
        collectivity: 'collectiviteit',
        er: 'eigen risico',
        bv: 'basisverzekering',
        av: 'aanvullende verzekering',
        tv: 'tandartsverzekering',
        basis: 'basisverzekering',
        aanvullend: 'aanvullende verzekering',
        tand: 'tandartsverzekering',
        '#basis-verzekering': 'basisverzekering',
        '#aanvullende-verzekering': 'aanvullende verzekering',
        '#tand-verzekering': 'tandartsverzekering'
    },
    BETAALTERMIJNEN: {
        J: 1,
        H: 2,
        K: 4,
        M: 12
    },
    FIELD: {
        INPUT: 'input',
        CHECKBOX: 'checkbox',
        DROPDOWN: 'select',
        RADIO: 'radio'
    },
    INTERACTION: {
        ENTER: 'enter field',
        EXIT: 'exit field',
        CHANGED: 'changed',
        SELECTED: 'selected'
    },
    ROUTES: {
        STAPPENPLAN: {
            AFGELOPEN_JAAR: 'afgelopen-jaar',
            ZORGKOSTEN: 'zorgkosten',
            AANKOMEND_JAAR: 'aankomend-jaar',
            JOUW_KEUZES: 'jouw-keuzes',
            JOUW_PAKKET: 'jouw-pakket',
            MEDISCHE_VRAGEN: 'medische-vragen',
            JOUW_OVERZICHT: 'jouw-overzicht',
            AFSLUITEN: 'afsluiten'
        },
        SALESFUNNEL: {
            START: 'start',
            PAKKET_KIEZEN: 'pakket-kiezen',
            PERSOONSGEGEVENS: 'persoonsgegevens',
            CONTROLEREN: 'controleren',
            WELKOM: 'welkom',
            AANVRAAG_GELUKT: 'aanvraag-gelukt',
            MEDISCHE_VRAGEN: 'medische-vragen',
            GEGEVENS_AANVULLEN: 'gegevens-aanvullen',
            PREMIE_BEREKENEN: 'premie-berekenen'
        }
    },
    SECTION_HEALTH_GOALS: 'health-goals',
    SECTION_REIMBURSEMENTS: 'reimbursements',
    TYPE_OF_INSURANCES: ['tand', 'aanvullend', 'basis'],
    SCROLL_TARGETS: {
        BV: '#basis-verzekering',
        COMBI: '#combi-verzekering',
        AV: '#aanvullende-verzekering',
        TV: '#tand-verzekering'
    },
    ZORGVERBRUIK_CLUSTERS: {
        EIGEN_RISICO: 'er',
        ALTERNATIEF: 'alternatief',
        BRIL: 'bril',
        FYSIO: 'fysio',
        ORTHODONTIE: 'orthodontie',
        TAND: 'tand'
    },
    HEALTHCARE_CONTRACT_STATUS: {
        CONTRACTED: 'Contracted',
        PARTIAL: 'Partial',
        IN_PROGRESS: 'InProgress',
        NONE: 'None'
    }
};
