import { APP_TYPE_PROSPECT, BETAALTERMIJNEN } from '@/constants';
import { ApiClient } from '@/utils/apiClient';
import { getLastUrlSegment, removeParamFromCurrentUrl } from '@/utils/url';
import { createSubState } from '@/utils/vuexHelpers';

const createStore = app => {
    const state = {
        verzekerden: {},
        betaaltermijnen: [],
        collectiviteit: null,
        key: null,
        isPayingExcessInInstallments: false,
        collectivityUsps: createSubState()
    };

    const getters = {
        getKey: state => {
            return state.key;
        },
        getProducts: (state, getters) => {
            const key = getters.getKey;
            return state.verzekerden[key] || null;
        },
        getProductsByIndex: (state, _getters, _rootState, rootGetters) => index => {
            const region = rootGetters['users/getSelectedRegionByIndex'](index);
            const key = !region
                ? `${index}-${rootGetters['users/getBetaaltermijn']?.soort}`
                : `${index}-${rootGetters['users/getBetaaltermijn']?.soort}-${region}`;

            return state.verzekerden[key] || null;
        },
        getProductByValue: (_state, getters) => (type, value) => {
            const product = getters.getProduct(type);
            return product?.find(p => {
                return p.verkorteOmschrijving === value;
            });
        },
        getProduct: (_state, getters) => type => {
            const products = getters.getProducts;
            return products ? getters.getProducts[type] || null : null;
        },
        hasNewProduct: (_state, getters) => type => {
            return getters.getProducts?.[type]?.some(p => p.isNieuw) || false;
        },
        hasProducts: (_state, getters) => {
            const products = getters.getProducts;
            return products ? Object.keys(getters.getProducts).length > 0 : false;
        },
        getEigenRisico: (_state, getters, _rootState, rootGetters) => {
            let product;

            const producten = getters.getProducts;
            const basisProducten = producten?.basis;

            if (!basisProducten) return null;

            const pakketkeuzes = rootGetters['users/getPakketKeuzes'];

            if (!pakketkeuzes?.basis) {
                //neem niet het eerste maar het laatste basisproduct (fix ivm DFR NBP)
                product = basisProducten.slice(-1)[0];
            } else {
                product = basisProducten.find(b => b.verkorteOmschrijving === pakketkeuzes.basis);
            }

            return {
                bedragVerplicht: producten.verplichtEigenRisicoBedrag,
                eigenRisicos: product?.eigenRisicos
            };
        },
        getInitieelEigenRisico: (_state, getters) => {
            const bv = getters.getInitieelProduct('basis');
            const ers = bv?.eigenRisicos;
            return ers?.find(er => er.isKomend) || ers?.find(er => er.isHuidig) || null;
        },
        getHuidigProduct: (_state, getters) => type => {
            const products = getters.getProduct(type);
            return products ? products.find(product => product.isHuidig) || null : null;
        },
        getKomendProduct: (_state, getters) => type => {
            const products = getters.getProduct(type);
            return products ? products.find(product => product.isKomend) || null : null;
        },
        heeftBeeindigdProduct: (_state, getters) => type => {
            const products = getters.getProduct(type);
            return products ? products.some(product => product.isBeeindigd) : false;
        },
        getInitieelProduct: (_state, getters) => type => {
            if (getters.heeftBeeindigdProduct(type)) return null;
            const initieelproduct =
                getters.getKomendProduct(type) || getters.getHuidigProduct(type);
            return initieelproduct;
        },
        //de premie van het laatste polisbeeld, dus inclusief de al geprolongeerde wijzigingen, maar zonder de wijzigingen die de klant in deze sessie doet
        getTotaalHuidigePremie: (_state, getters) => {
            const initieleBvPremie = getters.getInitieelEigenRisico?.nettoPremie || 0;
            const combiPremie = getters.getInitieelProduct('combi')?.nettoPremie || 0;
            const avPremie = getters.getInitieelProduct('aanvullend')?.nettoPremie || 0;
            const tvPremie = getters.getInitieelProduct('tand')?.nettoPremie || 0;
            const davPremie = getters.getInitieelProduct('dav')?.nettoPremie || 0;
            return initieleBvPremie + combiPremie + avPremie + tvPremie + davPremie;
        },
        getRelatieNummer: (_state, getters) => getters.getProducts?.relatienummer || null,
        getBvErDisabled: (_state, getters) => getters.getProducts?.bvErDisabled || false,
        getIsRondom18: (_state, getters) => {
            return getters.getProducts?.isRondom18Jaar || false;
        },
        getIsRondom18ByIndex: (_state, getters) => index => {
            const pakkettenInfo = getters.getProductsByIndex(index);
            return pakkettenInfo.isRondom18Jaar || false;
        },
        getCollectiviteit: state => state.collectiviteit ?? null,
        isPayingExcessInInstallments: state => state.isPayingExcessInInstallments ?? false,
        getIsBorderel: state => !!state.collectiviteit?.isBorderel,
        getBetaaltermijnen: state => state.betaaltermijnen ?? [],
        getNaamLaagsteAv: (_state, getters) => {
            const avProducts = getters.getProduct('aanvullend');
            return avProducts ? avProducts[0]?.naam : '';
        },
        getDigitaleBasispakkettenByIndex: (_state, getters) => index => {
            return getters
                .getProductsByIndex(index)
                ?.basis.filter(bv => bv.isDigitaal)
                .map(dbv => dbv.verkorteOmschrijving);
        },
        getDigitaleBasispakketten: (_state, getters) => {
            const vznDigitaleBvs = getters.getDigitaleBasispakkettenByIndex(1);
            const partnerDigitaleBvs = getters.getDigitaleBasispakkettenByIndex(2);
            const alleDigitaleBvs = vznDigitaleBvs
                ? partnerDigitaleBvs
                    ? [...new Set(vznDigitaleBvs.concat(partnerDigitaleBvs))]
                    : vznDigitaleBvs
                : partnerDigitaleBvs;

            return alleDigitaleBvs;
        },
        getCollectivityUsps: ({ collectivityUsps }) => collectivityUsps,
        getCollectivityNumber: (_state, getters, _rootState, rootGetters) => {
            const offerteData = rootGetters['prefill/getOfferteData'];
            const collectiviteyNumber =
                offerteData?.collectiviteitsnummer ||
                rootGetters['users/getCollectiviteitNr'] ||
                getters?.getCollectiviteit?.afspraaknummer;
            return collectiviteyNumber?.toString();
        }
    };

    const actions = {
        clear({ commit }) {
            commit('CLEAR');
        },
        clearCollectiviteit({ dispatch, commit }) {
            commit('CLEAR_COLLECTIVITEIT');
            dispatch('users/clearPolicyTerms', null, { root: true });
        },
        clearCollectivityUsps({ commit }) {
            commit('CLEAR_COLLECTIVITEIT_USPS');
        },

        async ophalen({ dispatch, commit, state, getters, rootGetters }) {
            const userIndex = rootGetters['users/getUserIndex'];
            const betaaltermijn = rootGetters['users/getBetaaltermijn'];
            const region = rootGetters['users/getSelectedRegion'];

            // cancel ophalen request when no user is selected
            if (userIndex === null) return;

            // they key defines unique ophalen request results
            if (betaaltermijn?.soort) {
                const newKey = !region
                    ? `${userIndex}-${betaaltermijn?.soort}`
                    : `${userIndex}-${betaaltermijn?.soort}-${region}`;

                // if newKey is same as state.key we have cache available to serve rebort
                if (newKey === getters.getKey) return;
                if (state.verzekerden.hasOwnProperty(newKey)) {
                    commit('SET_KEY', newKey);
                    dispatch('users/clearPakketkeuzeWhenUnavailable', userIndex, { root: true });
                    return;
                }
            }

            await dispatch(
                rootGetters['renderInfo/getAppType'] === 'prospect'
                    ? 'ophalenProspectPakketten'
                    : 'ophalenWijzigenPakketten'
            );
        },

        async ophalenProspectPakketten({ dispatch, commit, getters, rootGetters }) {
            const urlParams = new URLSearchParams(window.location.search);
            const currentPath = getLastUrlSegment(window.location.pathname);
            const offerteId = urlParams.get('offerteid');
            const regio = rootGetters['users/getSelectedRegion'];

            // Haal offerte data op. Vanuit de offertemail wordt direct naar de pakketkeuzepagina verwezen
            // De offerte data bevat ook de gegevens (geboortedatum, rol & pakketkeuzes) van de verzekerden
            // Deze moeten eerst worden opgehaald voordat de pakketten kunnen worden opgehaald
            if (currentPath === '/pakket-kiezen' && offerteId) {
                await dispatch('prefill/fetchOfferteData', offerteId, { root: true });
                if (rootGetters['prefill/getOfferteDataInvalid']) return;
            }

            const offerteData = rootGetters['prefill/getOfferteData'];
            const appContext = app.config.globalProperties.$jss.appContext();
            const propositie = appContext?.propositie;
            const betaaltermijn =
                BETAALTERMIJNEN[offerteData?.betaaltermijn] ||
                rootGetters['users/getBetaaltermijn']?.soort ||
                0;
            const userIndex = rootGetters['users/getUserIndex'];
            const collectiviteit = +getters.getCollectivityNumber;

            // map verzekerden
            const verzekerdenEntries = rootGetters['users/getVerzekerdenEntries'];
            const verzekerden = verzekerdenEntries.map(([key, verzekerde]) => {
                //const region = rootGetters['users/getSelectedRegionByIndex'](key);
                return (({ geboortedatum, rol, regio }) => ({
                    geboortedatum,
                    rol,
                    index: key,
                    ...(regio && { regio: regio })
                }))(verzekerde);
            });

            // check state
            if (!userIndex || !verzekerden.length || !verzekerden.every(v => v.geboortedatum)) {
                return;
            }

            if (
                propositie === 'AON' &&
                (isNaN(collectiviteit) ||
                    collectiviteit === null ||
                    collectiviteit === undefined ||
                    collectiviteit === 0)
            ) {
                return;
            }

            dispatch('setPendingProductenStatus', true, { root: true });
            dispatch('setErrorStatus', false, { root: true });

            try {
                // ophalen pakketten
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/ophalenprospectpakketten`,
                    {
                        verzekerden,
                        propositie,
                        betaaltermijn,
                        collectiviteit
                    }
                );

                commit('SET_PAKKETTEN', data.pakketSelectie);
                commit('SET_COLLECTIVITEIT', data.collectiviteit);
                commit('SET_BETAALTERMIJNEN', data.betaaltermijnen);
                commit(
                    'SET_KEY',
                    regio === null
                        ? `${userIndex}-${data.betaaltermijn?.soort}`
                        : `${userIndex}-${data.betaaltermijn?.soort}-${regio}`
                );

                dispatch('users/clearPakketkeuzeWhenUnavailable', userIndex, { root: true });

                dispatch('renderInfo/setRenderInfo', data.renderInfo, { root: true });
                dispatch('users/setBetaaltermijn', data.betaaltermijn, { root: true });
                dispatch('users/setDigitalePolisInfo', data.digitalePolisInfo, { root: true });
                dispatch('users/setIncassodatums', data.incassodatums, { root: true });

                // ophalen collectiviteit
                if (propositie === 'AON') {
                    commit('CLEAR_COLLECTIVITEIT');
                    dispatch('prefill/fetchCollectiviteit', collectiviteit, {
                        root: true
                    });
                    dispatch('users/clearPolicyTerms', null, { root: true });
                    commit('SET_COLLECTIVITEIT', data.collectiviteit);
                }

                if (rootGetters['prefill/getPrefillData']) {
                    const prefill = rootGetters['prefill/getPrefillData'];
                    const bv = prefill?.VerzekeringBasis;
                    const combi = prefill?.VerzekeringCombi;
                    const av = prefill?.VerzekeringAanvullend;
                    const tv = prefill?.VerzekeringTand;
                    const eigenRisicos = rootGetters['pakketten/getEigenRisico'].eigenRisicos;
                    const eigenrisico = eigenRisicos?.find(er => er.code === prefill?.EigenRisico);

                    // Check of producten bestaan, zo ja dan prefillen anders leeg laten
                    const pakketkeuzes = {
                        combi: getters.getProductByValue('combi', combi) ? combi : '',
                        aanvullend: getters.getProductByValue('aanvullend', av) ? av : '',
                        dav: getters.getProductByValue('dav', av) ? av : '',
                        tand: getters.getProductByValue('tand', tv) ? tv : ''
                    };

                    //bv en eigen risico properties alleen aanmaken als ze gevuld zijn door de klant
                    if (getters.getProductByValue('basis', bv)) {
                        pakketkeuzes.basis = bv;
                        pakketkeuzes.eigenrisico = Number(eigenRisicos[0].categorie);
                        pakketkeuzes.eigenrisicoCode = Number(eigenRisicos[0].code);
                    }
                    if (eigenrisico) {
                        pakketkeuzes.eigenrisico = eigenrisico.categorie;
                        pakketkeuzes.eigenrisicoCode = eigenrisico.code;
                    }

                    dispatch('users/setPakketSelectie', pakketkeuzes, { root: true });
                    dispatch('users/setPostcode', prefill?.Postcode, { root: true });
                    dispatch('users/setBetaaltermijn', prefill?.Betaaltermijn, { root: true });
                    dispatch('prefill/canRedirect', true, { root: true });
                }

                if (offerteData) {
                    const { personen } = offerteData;
                    const pakketkeuzes = {};

                    Object.keys(personen).forEach(index => {
                        const { hvKeuze, erKeuze, combiKeuze, avKeuze, tvKeuze, davKeuze } =
                            personen[index];
                        const producten = getters.getProductsByIndex(index);
                        const bv = producten.basis.find(
                            ({ verkorteOmschrijving }) => verkorteOmschrijving === hvKeuze
                        );
                        const er =
                            bv?.eigenRisicos.find(({ code }) => +code === +erKeuze) ||
                            producten.basis[0].eigenRisicos[0];
                        const combi = producten.combi?.find(
                            ({ verkorteOmschrijving }) => verkorteOmschrijving === combiKeuze
                        );
                        const av = producten.aanvullend?.find(
                            ({ verkorteOmschrijving }) => verkorteOmschrijving === avKeuze
                        );
                        const tv = producten.tand?.find(
                            ({ verkorteOmschrijving }) => verkorteOmschrijving === tvKeuze
                        );
                        const dav = producten.dav?.find(
                            ({ verkorteOmschrijving }) => verkorteOmschrijving === davKeuze
                        );

                        pakketkeuzes[index] = {
                            basis: bv?.verkorteOmschrijving || '',
                            eigenrisico: +er?.categorie,
                            eigenrisicoCode: +er?.code,
                            combi: combi?.verkorteOmschrijving || '',
                            aanvullend: av?.verkorteOmschrijving || '',
                            tand: tv?.verkorteOmschrijving || '',
                            dav: dav?.verkorteOmschrijving || ''
                        };
                    });

                    dispatch('users/setTotaalPakketSelectie', pakketkeuzes, { root: true });

                    // Na het ophalen van de offerte data is deze niet meer beschikbaar
                    // Daarom ook de query parameter verwijderen uit de URL
                    removeParamFromCurrentUrl('offerteid');
                }

                dispatch('prefill/clear', null, { root: true });
            } catch (error) {
                console.log('Kan geen pakketten ophalen:', error);
                dispatch('setErrorStatus', true, { root: true });
            } finally {
                dispatch('setPendingProductenStatus', false, { root: true });
            }
        },

        async ophalenWijzigenPakketten({ dispatch, commit, getters, rootGetters }) {
            const appContext = app.config.globalProperties.$jss.appContext();
            const userIndex = rootGetters['users/getUserIndex'];
            const relatienummer = rootGetters['login/getRelatienummer'];
            const isVznIngelogd = rootGetters['login/getIsVerzekeringnemerIngelogd'];
            const region = rootGetters['users/getSelectedRegion'];

            if (!userIndex || !relatienummer) return;

            if (!isVznIngelogd) {
                dispatch('setErrorStatus', true, { root: true });
                return;
            }
            dispatch('setPendingProductenStatus', true, { root: true });
            dispatch('setErrorStatus', false, { root: true });

            try {
                const propositie = appContext?.propositie;
                // ophalen pakketten
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/ophalenwijzigenpakketten`,
                    {
                        Index: userIndex,
                        Regio: region,
                        Propositie: propositie
                    }
                );

                commit('SET_PAKKETTEN', data.pakketSelectie);
                commit('SET_COLLECTIVITEIT', data.collectiviteit);
                commit('SET_PAYS_EXCESS_IN_INSTALLMENTS', data.isPayingExcessInInstallments);
                commit(
                    'SET_KEY',
                    !region
                        ? `${userIndex}-${data.betaaltermijn?.soort}`
                        : `${userIndex}-${data.betaaltermijn?.soort}-${region}`
                );

                dispatch('renderInfo/setRenderInfo', data.renderInfo, { root: true });
                dispatch('users/setBetaaltermijn', data.betaaltermijn, { root: true });
                dispatch('users/setIncassodatums', data.incassodatums, { root: true });
                dispatch('users/setDigitalePolisInfo', data.digitalePolisInfo, { root: true });

                // initiele keuzes bepalen
                const pakketkeuzes = {
                    basis: getters.getInitieelProduct('basis')?.verkorteOmschrijving,
                    eigenrisico: getters.getInitieelEigenRisico?.categorie,
                    eigenrisicoCode: getters.getInitieelEigenRisico?.code,
                    combi: getters.getInitieelProduct('combi')?.verkorteOmschrijving,
                    aanvullend: getters.getInitieelProduct('aanvullend')?.verkorteOmschrijving,
                    dav: getters.getInitieelProduct('dav')?.verkorteOmschrijving,
                    tand: getters.getInitieelProduct('tand')?.verkorteOmschrijving
                };
                dispatch('users/setPakketSelectie', pakketkeuzes, { root: true });

                // Maak voor huidige user index een verzekerde object aan, als deze nog niet bestaat
                // Zodat deze gekoppeld kan worden aan de bijbehorende pakketselectie
                // Noodzakelijk voor o.a. het bonnetje
                const verzekerde = rootGetters['users/getVerzekerde'];
                if (!verzekerde) {
                    const key = getters.getKey;
                    if (key) {
                        dispatch(
                            'users/setVerzekerde',
                            {
                                geboortedatum: data.pakketSelectie[key].geboortedatum,
                                naam: data.pakketSelectie[key].naam
                            },
                            { root: true }
                        );
                    }
                }
            } catch (error) {
                console.log('Kan geen pakketten ophalen:', error.message);
                dispatch('setErrorStatus', true, { root: true });
            } finally {
                dispatch('setPendingProductenStatus', false, { root: true });
            }
        },

        async ophalenLandenEnNationaliteiten({ dispatch, rootGetters }) {
            const appContext = app.config.globalProperties.$jss.appContext();
            const landen = rootGetters['users/getLanden'];
            const nationaliteiten = rootGetters['users/getNationaliteiten'];

            // Haal geen lijsten op als alle lijsten al zijn gevuld in de store
            if ([landen, nationaliteiten].every(lijst => !!lijst.length)) {
                return;
            }

            try {
                // ophalen pakketten
                const { data } = await ApiClient(appContext).get(
                    `/clientcontroller/salesfunnelclient/ophalenlandenennationaliteiten`
                );

                dispatch('users/setProspectLijsten', data, { root: true });
            } catch (error) {
                console.log('Kan geen lijsten ophalen:', error.message);
                dispatch('setErrorStatus', true, { root: true });
            }
        },

        setDisabledTandPakketten: ({ commit, getters }, tandPakketten) => {
            // loop door alle pakketten die disabled moeten worden
            tandPakketten.forEach(pakket => {
                const pakketIndex = getters
                    .getProduct('tand')
                    .findIndex(p => p.verkorteOmschrijving === pakket);

                commit('SET_DISABLED_TAND_PAKKET', {
                    key: getters.getKey,
                    pakketIndex: pakketIndex,
                    isDisabled: true
                });
            });
        },

        setDisabledTandPakkettenForUser: ({ commit, getters }, tandPakketten) => {
            // loop door alle pakketten die disabled moeten worden
            tandPakketten.forEach(pakket => {
                const pakketIndex = getters
                    .getProduct('tand')
                    .findIndex(p => p.verkorteOmschrijving === pakket);

                commit('SET_DISABLED_TAND_PAKKET', {
                    key: tandPakketten.userIndex,
                    pakketIndex: pakketIndex,
                    isDisabled: true
                });
            });
        },

        async fetchCollectivityUsps({ commit, getters }) {
            if (getters.getCollectivityUsps?.data?.afspraaknummer === getters.getCollectivityNumber)
                return;

            commit('SET_COLLECTIVITY_USPS_ERROR', false);
            commit('SET_COLLECTIVITY_USPS_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/collectiviteitUsps`,
                    {
                        Collectiviteitnummer: getters.getCollectivityNumber,
                        Propositie: appContext.propositie
                    }
                );

                if (data?.afspraaknummer) {
                    commit('SET_COLLECTIVITY_USPS_DATA', data);
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_COLLECTIVITY_USPS_ERROR', true);
            } finally {
                commit('SET_COLLECTIVITY_USPS_PENDING', false);
            }
        }
    };

    const mutations = {
        SET_PAKKETTEN(state, pakketten) {
            state.verzekerden = { ...state.verzekerden, ...pakketten };
        },
        SET_BETAALTERMIJNEN(state, betaaltermijnen) {
            state.betaaltermijnen = betaaltermijnen;
        },
        SET_COLLECTIVITEIT(state, collectiviteit) {
            state.collectiviteit = collectiviteit;
        },
        SET_PAYS_EXCESS_IN_INSTALLMENTS(state, isPayingExcessInInstallments) {
            state.isPayingExcessInInstallments = isPayingExcessInInstallments;
        },
        SET_DISABLED_TAND_PAKKET: (state, { key, pakketIndex, isDisabled }) => {
            if (!state.verzekerden.hasOwnProperty(key)) {
                state.verzekerden[key].tand[pakketIndex][key] = isDisabled;
            } else {
                state.verzekerden[key].tand[pakketIndex]['isDisabled'] = isDisabled;
            }
        },
        SET_KEY(state, key) {
            state.key = key;
        },
        SET_COLLECTIVITY_USPS_DATA(state, data) {
            state.collectivityUsps.data = data;
        },
        SET_COLLECTIVITY_USPS_PENDING: (state, pending) => {
            state.collectivityUsps.pending = pending;
        },
        SET_COLLECTIVITY_USPS_ERROR: (state, error) => {
            state.collectivityUsps.error = error;
        },
        CLEAR(state) {
            state.verzekerden = {};
            state.betaaltermijnen = [];
            state.key = null;
        },
        CLEAR_COLLECTIVITEIT(state) {
            state.collectiviteit = null;
        },
        CLEAR_COLLECTIVITEIT_USPS(state) {
            state.collectivityUsps = createSubState();
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const productsStorePaths = ['pakketten'];

export default app => createStore(app);
