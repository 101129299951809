/**
 * Controleer of datum in de toekomst ligt
 * op basis van vandaag
 * @param {string|Date} date
 * @returns {boolean}
 */
export const isFutureDate = date => {
    const maxDate = new Date(Date.now()).setHours(0, 0, 0, 0);
    const compareDate = new Date(date).setHours(0, 0, 0, 0);

    return compareDate > maxDate;
};

/**
 * Geeft eerstvolgende ingangsdatum terug op basis van geboortedatum
 * BV: 11 november 2004 => 1 december 2022 (huidige jaar = 2022)
 * @param {string|Date} geboortedatum
 * @returns {Date}
 */
export const getIngangsdatumNa18eVerjaardag = geboortedatum => {
    const datum = new Date(geboortedatum);
    //bepaal verjaardag:
    datum.setFullYear(datum.getFullYear() + 18);

    //bepaal offset om overgang zomer/wintertijd te kunnen corrigeren
    const offsetOpVerjaardag = datum.getTimezoneOffset();

    //niet eerst setMonth(maand + 1) en dan setDate(1), maar ga in 1 keer naar eerste van de volgende maand,
    //om fouten op de 31ste van de maand te voorkomen:
    //31 mei + 1 maand -> 31 juni wordt 1 juli, want 31 juni bestaat niet.
    datum.setMonth(datum.getMonth() + 1, 1);

    //bepaal eventuele zomer/wintertijdovergang in minuten tussen verjaardag en nieuwe datum
    const offsetVerschil = datum.getTimezoneOffset() - offsetOpVerjaardag;

    //corrigeer voor zomer/wintertijd overgang, want anders komt de datum soms op de vorige dag om 23:00 uit.
    datum.setMinutes(datum.getMinutes() - offsetVerschil);

    return datum;
};

/**
 * Verkrijg verschil tussen 2 datums in maanden
 * Wordt standaard naar boven afgerond
 * @param {Date|string} datum1
 * @param {Date|string} datum2
 * @param {boolean} afronden
 * @returns {number}
 */
export const getMonthDiff = (datum1, datum2, afronden = true) => {
    let startdatum = new Date(datum1);
    let einddatum = new Date(datum2);
    let inverse = false;

    if (datum1 > datum2) {
        startdatum = datum2;
        einddatum = datum1;
        inverse = true;
    }

    const yearsDifference = einddatum.getFullYear() - startdatum.getFullYear();
    const monthsDifference = einddatum.getMonth() - startdatum.getMonth();
    const daysDifference = einddatum.getDate() - startdatum.getDate();
    let monthCorrection = 0;

    if (afronden === true && daysDifference > 0) {
        monthCorrection = 1;
    } else if (afronden !== true && daysDifference < 0) {
        monthCorrection = -1;
    }

    return (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection);
};

/**
 * Controleer of ingangsdatum binnen een termijn van 4 maanden zit
 * T.o.v. het systeemdatum
 * @param {string|date} ingangsdatum
 * @returns {boolean}
 */
export const isTeLateAanmelding = ingangsdatum =>
    getMonthDiff(new Date(ingangsdatum), new Date()) > 4;

/**
 * Check if date is a Date object and is valid
 * @param {Date} date
 * @returns {boolean}
 */
export function isDate(date) {
    return date instanceof Date && !isNaN(date.getTime());
}
